import { NgModule }             				from '@angular/core';
import { RouterModule, Routes } 				from '@angular/router';

import { SignupComponent } 						from './signup/signup.component';
import { SigninComponent } 						from './signin/signin.component';
import { ResetPasswordComponent } 				from './reset-password/reset-password.component';


// PERSONAL ROUTES
import { AccountSettingsComponent } 			from './account-settings/account-settings.component';
import { ReportIssueComponent } 				from './report-issue/report-issue.component';

import { UserActivationComponent } 				from './user-activation/user-activation.component';
import { ResetPasswordCompleteComponent } 		from './reset-password-complete/reset-password-complete.component';


import { AddNewComponent } 						from './supply/add-new/add-new.component';
import { SearchComponent } 						from './supply/search/search.component';




// V2

import { LazadaSearchComponent } 				from './lazada/lazada-package/lazada-search/lazada-search.component';

import { DashboardComponent } 					from './dashboard/dashboard.component';
import { ShuntComponent } 						from './shunt/shunt.component';

import { PickupOther3plComponent } 				from './pickup-other3pl/pickup-other3pl.component';
import { PickupShipperComponent } 				from './pickup-shipper/pickup-shipper.component';
import { PickupCustomerComponent } 				from './pickup-customer/pickup-customer.component';

import { InboundComponent } 					from './inbound/inbound.component';

import { OutboundDeliveryComponent } 			from './outbound-delivery/outbound-delivery.component';
import { OutboundTransferComponent } 			from './outbound-transfer/outbound-transfer.component';
import { OutboundReturnComponent } 				from './outbound-return/outbound-return.component';
import { OutboundWarehouseComponent } 				from './outbound-warehouse/outbound-warehouse.component';


import { LazadaOrdersComponent }             	from './lazada/lazada-reports/lazada-orders/lazada-orders.component';
import { BulkStatusComponent }             		from './lazada//bulk-status/bulk-status.component';

import { RsvpComponent }             			from './rsvp/rsvp.component';


// -----------








import { LazadaDashboardComponent } 			from './lazada/lazada-dashboard/lazada-dashboard.component';
import { LazadaZipcodeComponent } 				from './lazada/lazada-dashboard/lazada-zipcode/lazada-zipcode.component';

import { LazadaScanComponent } 					from './lazada/lazada-package/lazada-scan/lazada-scan.component';
import { LazadaInTransitComponent } 			from './lazada/lazada-package/lazada-in-transit/lazada-in-transit.component';
import { LazadaOrderComponent } 				from './lazada/lazada-package/lazada-order/lazada-order.component';
import { LazadaOrderTimelineComponent } 		from './lazada/lazada-package/lazada-order-timeline/lazada-order-timeline.component';
import { LazadaTrackingComponent } 				from './lazada/lazada-tracking/lazada-tracking.component';

import { LazadaStatusComponent }             	from './lazada/lazada-reports/lazada-status/lazada-status.component';
import { ImportOrdersExcelComponent }           from './import-orders-excel/import-orders-excel.component';

import { ProfileComponent } 					from './user/profile/profile.component';

import { HubLocationsComponent } 					from './hub-locations/hub-locations.component';



const routes: Routes = [
	{ path: '', 								component: SigninComponent },
	{ path: 'login', 							component: SigninComponent },
	{ path: 'activate', 						component: UserActivationComponent },
	{ path: 'reset-password', 					component: ResetPasswordComponent },
	{ path: 'complete-reset-password', 			component: ResetPasswordCompleteComponent },
	
	{ path: 'account-settings', 				component: AccountSettingsComponent },
	{ path: 'report-issue', 					component: ReportIssueComponent },

	{ path: 'orders', 							component: LazadaSearchComponent },
	{ path: 'inbound', 							component: InboundComponent },	
	{ path: 'outbound/out-for-delivery', 		component: OutboundDeliveryComponent },	
	{ path: 'outbound/transfer-to-hub', 		component: OutboundTransferComponent },	
	{ path: 'outbound/send-returns', 			component: OutboundReturnComponent },	
	{ path: 'outbound/return-to-warehouse', 	component: OutboundWarehouseComponent },	
	
	{ path: 'pickup/other3pl', 					component: PickupOther3plComponent },	
	{ path: 'pickup/shipper', 					component: PickupShipperComponent },	
	{ path: 'pickup/customer', 					component: PickupCustomerComponent },	
	{ path: 'shunt', 							component: ShuntComponent },	
	{ path: 'dashboard', 						component: DashboardComponent },	



	{ path: 'admin/orders-import',				component: ImportOrdersExcelComponent },
	{ path: 'admin/orders-report',				component: LazadaOrdersComponent },
	{ path: 'staffs/add-new', 					component: SignupComponent },
	{ path: 'partners/add-new', 				component: AddNewComponent },	



	// { path: 'lazada', 							component: LazadaDashboardComponent },
	{ path: 'dashboard/stats', 			component: LazadaDashboardComponent },
	{ path: 'dashboard/zipcode', 		component: LazadaZipcodeComponent },
	{ path: 'packages/scan', 			component: LazadaScanComponent },
	{ path: 'packages/in-transit',		component: LazadaInTransitComponent },
	{ path: 'packages/:code',			component: LazadaOrderComponent },
	{ path: 'lazada',					component: LazadaTrackingComponent },
	{ path: 'merchant',					component: LazadaTrackingComponent },
	{ path: 'packages/:code/timeline',	component: LazadaOrderTimelineComponent },
	{ path: 'reports/status',			component: LazadaStatusComponent },
	{ path: 'bulk-status',				component: BulkStatusComponent },

	{ path: 'dashboard', 						component: DashboardComponent },
	
	{ path: 'supply/search', 					component: SearchComponent },	
	
	{ path: 'user/profile', 					component: ProfileComponent },
	
	{ path: 'hubs', 							component: HubLocationsComponent },
	
	{ path: 'customer', 					component: RsvpComponent },
];


@NgModule({
	imports: [ RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'}), ],
	exports: [ RouterModule ]

})
export class AppRoutingModule {}
