import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } 			from  '../api.service';

@Component({
  selector: 'app-reset-password-complete',
  templateUrl: './reset-password-complete.component.html',
  styleUrls: ['./reset-password-complete.component.css']
})
export class ResetPasswordCompleteComponent implements OnInit {

	code: any;
	email: any;
	message: any;

  constructor(
  		private apiService:  ApiService,
		private route: ActivatedRoute,
    	private router: Router
    ) { }

  ngOnInit() {

  	this.route
		.queryParams
		.subscribe(params => {
			this.code = params['code'] || '';
			this.email = params['email'] || '';
			this.message = 'Loading...';
			console.log(this.code);
			// this.location.replaceState('/positions');

			this.resetPasswordComplete();
	});

  }

  resetPasswordComplete() {
		this.apiService.resetPasswordComplete(this.code, this.email).subscribe(data => {

		this.message 	= data.message;
	});
  }

}
