import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService }           from  '../api.service';
import { ExcelService }                       from '../excel.service';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { NgxBarcodeModule } from 'ngx-barcode';

// declare function openModal(): any;
// declare function closeModal(): any;

@Component({
  selector: 'app-pickup-customer',
  templateUrl: './pickup-customer.component.html',
  styleUrls: ['./pickup-customer.component.css'],
  providers: [ExcelService]
})
export class PickupCustomerComponent implements OnInit {

	form: FormGroup;
	type = 'status';
  // options:any;
  stats:Object = {};
  lastRefreshed: any;
  lastAssignRefreshed: any;
  pendingAssign: any;
  trips: any;

  revokeCompleted = 0;
  orfCompleted = 0;
  orfQuality = 22;
  orfData: any;
  contentDataURL: any = []

  submitted = false;

  company: any;

  TNModel: any;
  TNModelRevoke: any;
  element: HTMLElement;
  pdf: any;

  orders: Array<Object> = [];
  ordersRevoke: Array<Object> = [];
  ordersOrf: Array<Object> = [];
  failedOrders: Array<String> = [];
  failedRevokeOrders: Array<String> = [];
  failedOrfOrders: Array<String> = [];

  elementType = 'img';
  value = '';
  format = 'CODE128';
  lineColor = '#000000';
  width = 1.3;
  height = 54;
  displayValue = true;
  fontOptions = '';
  font = 'calibri';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 14;
  background = '#fff';
  margin = 0;
  marginTop = 0;
  marginBottom = 0;
  marginLeft = 0;
  marginRight = 0;



  constructor(private fb: FormBuilder,
    private apiService: ApiService,
    private excelService: ExcelService) { }

  get f() { return this.form.controls; }

  ngOnInit() {

  	this.form = this.fb.group({
        tracking_number: ['', [Validators.required]],
        driver_id: ['', [Validators.required]],
        company_id: ['', [Validators.required]],
        // transport_id: ['', []],
        limit_count: ['', []],
        delivery_id: ['',  []],
    });

    this.loadIndex(true);

  }

  index($id = null, $key) {

    this.stats[$key] = null;

    this.apiService.customerReturnOptions($id).subscribe(data => {

            // this.options     = data.data;
            this.stats[$key]       = data.data;
            this.lastRefreshed = new Date();
        });
    // 
  }

  loadIndex(force:boolean) {

    // if(this.lastRefreshed !== null)
        if(force || !this.stats) {
        
        this.lastRefreshed = null;
          
          this.index(59, 'confirmed');
          this.index(40, 'assigned');
          this.index(60, 'schedule_success');
          this.index(61, 'schedule_failed');
          this.index(42, 'pickup_success');
          this.index(41, 'pickup_failed');
          // this.index(19, 'delivered');
          // this.index(62, 'reschedule_success');
          // this.index(63, 'reschedule_failed');
          this.index(64, 'repickup_success');
          this.index(65, 'repickup_failed');
          this.index(44, 'inbound_hub');
          this.index(48, 'inbound_sc');  
        }
      
    }

  confirmImportedPickup() {
    this.apiService.customerReturnConfirmImported().subscribe(data => {

        if(data.status)
          this.loadIndex(true);
    });
  }

  confirmReceivedPickup() {
    this.apiService.customerReturnConfirmReceived().subscribe(data => {

        if(data.status)
          this.loadIndex(true);
    });
  }

  confirmReattempt() {
    this.apiService.customerReturnConfirmReattempt().subscribe(data => {

        if(data.status)
          this.loadIndex(true);
    });
  }

  endAttemptedTwice() {

    // console.log('Ending');
     this.apiService.customerReturnEndReattempted().subscribe(data => {
       
        if(data.status) {
          this.loadIndex(true);
        }
    }); 
  }


  assignIndex() {

    this.orders = [];
    this.pendingAssign = null;

    this.apiService.customerReturnAssignIndex().subscribe(data => {

        if(data.status)
          this.pendingAssign = data.data;

        this.lastAssignRefreshed = new Date();
    });
  }

  districtSelected(state, city, district, event) {

    if(event.target.checked) {

      for (var i = this.pendingAssign['orders'][state][city][district].length - 1; i >= 0; i--) {
        this.orders.push(this.pendingAssign['orders'][state][city][district][i]);
      }
    }
    else {
      for (var i = this.pendingAssign['orders'][state][city][district].length - 1; i >= 0; i--) {
        let id = this.orders.indexOf(this.pendingAssign['orders'][state][city][district][i]);
        this.orders.splice(id,1);
      }
    }
  }

  onCompanyChange(event) {
        
        // this.driver = null;
        this.form.controls['driver_id'].setValue('');
        this.form.controls['delivery_id'].setValue('');

        this.trips = null;

        this.company = this.pendingAssign.options.find(x => x.id == event.target.value);
    }

  onDriverChange(event) {
    this.apiService.customerReturnDriverTrips(event.target.value).subscribe(data => {

        this.trips       = data.data;

        if(this.trips.length > 0)
          this.form.controls['delivery_id'].setValue(this.trips[0].id);
        else
          this.form.controls['delivery_id'].setValue('');
    });
  }

  start() {

    this.submitted = true;

    // if(this.form.valid) {
      // this.form.controls['driver_id'].disable();
      // this.form.controls['delivery_id'].disable();
      // this.form.controls['limit_count'].disable();

      // this.orders = [];
      
      let values =  (<HTMLInputElement>document.getElementById("tnModel")).value.split("\n");

      if(values.length > 0) {
        let orders: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            orders.push({'trackingNumber': values[i]});
        }

        this.orders = this.orders.concat(orders);
      }
      
      this.assignOrder(this.orders, 0);
      (<HTMLInputElement>document.getElementById("tnModel")).value = '';
    
      // console.log(this.orders);
  }

  assignOrder(orders, index) {

    this.form.controls['tracking_number'].setValue(orders[index]['trackingNumber']);

       this.apiService.customerReturnAssignOrder(this.form.getRawValue()).subscribe(data => {

          if (data.status) {

            if('data' in data) {
              this.trips = data.data;

              if(this.trips.length > 0)
                this.form.controls['delivery_id'].setValue(this.trips[0]['id']);
            }

            if((index+1) < orders.length) {
              if(this.form.controls['limit_count'].value === '')
                this.assignOrder(orders, index+1);
              else {
                if((index+1) < this.form.controls['limit_count'].value)
                  this.assignOrder(orders, index+1);
                else {
                  this.resetForm();
                  this.trips = null;
                }
              }  
            }
            else {
              this.resetForm();
              this.trips = null;
            }
          }  
          else {
            // this.resetForm();
            // this.failedOrders = [];
            this.failedOrders.push(orders[index]['trackingNumber']);

            this.assignOrder(orders, index+1);
            // alert(data.message);
          }
        });
  }

  clearFailed() {

    this.failedOrders = [];
  }

  clearFailedRevoke() {

    this.failedRevokeOrders = [];
  }
  
  clearFailedOrf() {

    this.failedOrfOrders = [];
    this.contentDataURL = [];
  }

  resetForm() {
    this.submitted = false;
    
    this.form.controls['company_id'].enable();
    this.form.controls['driver_id'].enable();
    this.form.controls['delivery_id'].enable();
    this.form.controls['limit_count'].enable();

    this.form.controls['company_id'].setValue('');
    this.form.controls['driver_id'].setValue('');
    this.form.controls['delivery_id'].setValue('');
    this.form.controls['limit_count'].setValue('');
    
    this.orders = null;
    this.assignIndex();
  }

  revokeUnscheduled(deliveryId) {

    this.apiService.customerReturnAssignRevoke(deliveryId).subscribe(data => {

        if(data.status)
          this.assignIndex();

    });
  }

  download(type) {

    this.apiService.customerReturnDownload(type).subscribe(data => {

      if (data.status) {
          if(data.data.length !== 0)
              this.excelService.exportAsExcelFile(data.data, 'lazada_orders_'+type);
          else
              alert('No orders found to export.');
      } else {
          alert(data.message);
      }

    });
  }

  startManualConfirm() {

      // this.orders = [];
      
      let values =  (<HTMLInputElement>document.getElementById("tnModel")).value.split("\n");

      if(values.length > 0) {
        let orders: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            orders.push({'trackingNumber': values[i]});
        }

        // this.orders = orders;
          this.bulkManual(orders, 0);
      }
      
    }

    bulkManual(orders, index) {

           this.apiService.customerReturnManualConfirm(orders[index]['trackingNumber']).subscribe(data => {

              if (!data.status) {
                this.failedOrders.push(orders[index]['trackingNumber']+' | '+data.message); 
                
              }
              else  
                this.orders.push(orders[index]['trackingNumber']+' | '+data.message); 

              if((index+1) < orders.length) {
                      this.bulkManual(orders, index+1);  
                }
                else {
                  this.resetManualForm();
                  // this.loadDeliveryOrders(this.form.controls['delivery_id'].value);
              }

            });
    }

    resetManualForm() {
        (<HTMLInputElement>document.getElementById("tnModel")).value = '';
        var msg = '';
        msg =  'Completed all manual confirmation, please check for errors.';
        alert(msg);
    }

    resetRevokeForm() {
        (<HTMLInputElement>document.getElementById("tnModelRevoke")).value = '';
        var msg = '';
        msg =  'Completed all revoke requested, please check for errors.';
        alert(msg);
    }

    resetOrfForm() {
        (<HTMLInputElement>document.getElementById("tnModelOrf")).value = '';
        var msg = '';
        msg =  'Downloaded all ORF requested, please check for errors.';
        // alert(msg);
    }


    startBulkRevoke() {

      this.ordersRevoke = [];
      this.revokeCompleted = 0;
      this.failedRevokeOrders = [];

      let values =  (<HTMLInputElement>document.getElementById("tnModelRevoke")).value.split("\n");

      if(values.length > 0) {
        let ordersRevoke: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            ordersRevoke.push({'trackingNumber': values[i]});
        }

          this.ordersRevoke = ordersRevoke;
          this.bulkRevoke(ordersRevoke, 0);
      }      
    }

    bulkRevoke(orders, index) {

      this.apiService.customerReturnRevokeDriver(orders[index]['trackingNumber']).subscribe(data => {
          if (!data.status) {
            this.failedRevokeOrders.push(orders[index]['trackingNumber']+' | '+data.message); 
          }
          else  {
            this.revokeCompleted++;
          }
          if((index+1) < orders.length) {
                this.bulkRevoke(orders, index+1);  
          }
          else {
            this.resetRevokeForm();
          }
      });
    }

    startBulkOrf() {

      this.ordersOrf = [];
      this.orfCompleted = 0;
      this.failedOrfOrders = [];
      this.contentDataURL = [];

      let values =  (<HTMLInputElement>document.getElementById("tnModelOrf")).value.split("\n");

      if(values.length > 0) {
        let ordersOrf: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            ordersOrf.push({'trackingNumber': values[i]});
        }

          this.ordersOrf = ordersOrf;
          this.bulkOrf(ordersOrf, 0);
      }      
    }

    bulkOrf(orders, index) {

      this.apiService.customerReturnPrintOrf(orders[index]['trackingNumber']).subscribe(data => {
          if (!data.status) {
            this.failedOrfOrders.push(orders[index]['trackingNumber']+' | '+data.message); 

            if((index+1) < orders.length) {
              this.bulkOrf(orders, index+1);  
            }
            else {
              this.ordersOrf = [];
              this.resetOrfForm();
            }
          }
          else  {
            this.orfData = data.data
            this.downloadOrf(data.data, index, orders)
          }

          
      });
    }

    downloadOrf(data, index, orders) {

      let self = this;

      setTimeout(function(){
          let data2 = (<HTMLInputElement>document.getElementById('orf'));  

          html2canvas(data2, {
            scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            scale: self.orfQuality
          }).then(canvas => {
                // closeModal();
                self.contentDataURL.push(canvas) 
                // console.log(contentDataURL.toString())
                self.pdf = new jspdf('p', 'cm', 'a6'); // A4 size page of PDF
                
                if(self.contentDataURL.length === 1)
                  self.pdf.addImage(self.contentDataURL[0], 'png', 0, 0, 10.5, 14.8)

                else {
                  self.pdf.addImage(self.contentDataURL[0], 'png', 0, 0, 10.5, 14.8)

                  for (var i = 1; i < self.contentDataURL.length; i++) {
                    self.pdf.addPage('p', 'cm', 'a6');
                    self.pdf.addImage(self.contentDataURL[i], 'png', 0, 0, 10.5, 14.8)
                  }
                }
                
                self.orfCompleted++;

                if((index+1) < orders.length) {
                  self.bulkOrf(orders, index+1);  
                  }
                else {
                  self.pdf.save('multple_tns'+ '.pdf'); // Generated PDF
                  
                  self.contentDataURL = [];
                  self.ordersOrf = [];
                  self.resetOrfForm();
                }
          }); 
      }, 1000);
    }
}
