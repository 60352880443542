import { Component, OnInit, Input } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.css']
})
export class ReportIssueComponent implements OnInit {

	form: FormGroup;
    submitted = false;

  constructor(
  		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder
	) { }

  ngOnInit() {
  	this.form = this.fb.group({
        title: ['', 	[Validators.required]],
        content: ['', 	[Validators.required]],
    });

  }

  get f() { return this.form.controls; }


  report(): void {
  	this.submitted = true;

  	if (this.form.valid) {
		this.apiService.systemReportIssue(this.form.value).subscribe(data => {

			if (data.status == true) {
				this.form.reset();
			}

			this.submitted = false;
			alert(data.message);
		});
	}
  }


}
