import { Component, OnInit, Input } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

	declarativeFormCaptchaValue: any;

	form: FormGroup;
    submitted = false;

  	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder
	) { }

	ngOnInit() {
		this.form = this.fb.group({
            email: ['', 	[Validators.required, Validators.email]],
        });
	}

	get f() { return this.form.controls; }

	resolved(captchaResponse: string) {
		// console.log(`Resolved captcha with response ${captchaResponse}:`);
	}

	reset(form: NgForm): void {

		if (!form.valid) {
			alert('Please make sure you are not a robot.');
		} else {
			this.submitted = true;

			if (this.form.valid) {
				this.apiService.resetPassword(this.form.value).subscribe(data => {

					if (data.status == true) {
						this.submitted = false;
						this.form.reset();
					}

					alert(data.message);
					// this.router.navigate(['/positions']);
				});


			}
		}


		// if(this.form.) {
		// 	this.apiService.resetPassword(this.form.value).subscribe(data => {
		// 		alert(data.message);
		// 		this.router.navigate(['/positions']);
		// 	});
		// }
		// else {
		// 	alert("Email is required to reset password!");
		// }
	}

	validated(form: NgForm): boolean {

		if (form.value.email === '') {
			return false;
		}
		return true;
	}

}
