import { DatePipe } from '@angular/common';
import { Component, OnInit } 					from '@angular/core';
import { FormBuilder, FormGroup, Validators } 	from '@angular/forms';
import { Subscription } 						from 'rxjs';
import { ApiService } 							from  '../../../api.service';
import { Router} 								from '@angular/router';
import { ExcelService }                   		from '../../../excel.service';


@Component({
	selector: 'app-lazada-orders',
	templateUrl: './lazada-orders.component.html',
	styleUrls: ['./lazada-orders.component.css'],
	providers: [ExcelService, DatePipe]
})
export class LazadaOrdersComponent implements OnInit {

	reportForm: FormGroup;

	submitted = false;
	bulksubmitted = false;

	orders: Array<String> = [];
	downloadingdates = [];
	generatedExportData: Array<Object> = [];

	retrieved = 0;
	total = 0;

	options: any;
	audio:any;

	constructor(
		private apiService: ApiService,
		private router: Router,
		private fb: FormBuilder,
		private excelService: ExcelService,
		public datepipe: DatePipe
		) { 

		this.audio = new Audio('assets/files/report_day_downloaded.wav');
        this.audio.load();
	}

	get rf() { return this.reportForm.controls; }

	ngOnInit() {

		this.reportForm = this.fb.group({
			received_from: 	['', [Validators.required]],
			received_until: ['', [Validators.required]],
			type: 			['', [Validators.required]],
			process: 		['all', [Validators.required]],
			billing: 		['unbilled', [Validators.required]],
			pickup_id: 		['', []],
			repickup_id: 		['', []],
			drtm_id: 		['', []],
		});

        this.setTodayDatePicker();
        this.index();
	}

	index() {

	    // this.orders = [];
	    // this.pendingAssign = null;

	    this.apiService.lazadaOrdersReportIndex().subscribe(data => {

	        if(data.status)
	          this.options = data.data;
	      	else
	      		alert(data.message);

	      	// console.log(this.options);

	        // this.lastAssignRefreshed = new Date();
	    });
	}

	generateByDate() {

		this.generatedExportData = [];

		this.submitted = true; 

		let startDate = this.reportForm.controls['received_from'].value;
		let endDate = this.reportForm.controls['received_until'].value;

		var daylist = this.getDaysArray(new Date(startDate), new Date(endDate));

		

		if(this.reportForm.valid) {

			for (var i = daylist.length - 1; i >= 0; i--) {
				this.reportForm.controls['received_from'].setValue(daylist[i]);
				this.reportForm.controls['received_until'].setValue(daylist[i]);
				this.downloadingdates.push(daylist[i]);
				// console.log(i + 1);
				this.generate(i, daylist[i]);
				// this.reportForm.controls['received_from'].setValue('');
				// this.reportForm.controls['received_until'].setValue('');
				// this.reportForm.controls['process'].setValue('all');
			}

			this.submitted = false;
		}
	}

	getDaysArray(startDate, endDate) {
	    for(var arr=[],dt=new Date(startDate); dt<=endDate; dt.setDate(dt.getDate()+1)){
	        arr.push(this.datepipe.transform(new Date(dt), 'yyyy-MM-dd'));
	    }
	    return arr;
	};

	clearPartner(type) {
		if(type === 'pickup')
			this.reportForm.controls['pickup_id'].setValue('')
		else if(type === 'repickup')
			this.reportForm.controls['repickup_id'].setValue('')
		else if(type === 'drtm')
			this.reportForm.controls['drtm_id'].setValue('')

	}


	async generate(i, day) {

		// this.reportForm.controls['received_from'].setValue(this.datepipe.transform(this.reportForm.controls['received_from'].value, 'yyyy-MM-dd'));
		// this.reportForm.controls['received_until'].setValue(this.datepipe.transform(this.reportForm.controls['received_until'].value, 'yyyy-MM-dd'));

		// this.submitted = true;

		// let self = this;

		// if(this.reportForm.valid) {
			this.apiService.lazadaOrdersReport(this.reportForm.getRawValue()).subscribe(data => {

            	// self.reportForm.controls['received_from'].setValue(self.datepipe.transform(self.reportForm.controls['received_from'].value, 'yyyy-MM-dd'));
				// self.reportForm.controls['received_until'].setValue(self.datepipe.transform(self.reportForm.controls['received_until'].value, 'yyyy-MM-dd'));
            	

                if (data.status) {
                    if(data.data.length !== 0) {

                    	for (var j = data.data.length - 1; j >= 0; j--) {
                    		this.generatedExportData.push(data.data[j]);
                    	}
                    }

                    
                    // else
                    //     alert('No orders found to export.');
                } else {
                    alert(data.message);
                }

                this.audio.play();
                this.downloadingdates.splice(this.downloadingdates.indexOf(day), 1);
				// console.log(day);

	            if(this.downloadingdates.length === 0) {
					// setTimeout(()=>{
		                	this.setTodayDatePicker();
		            		
		            		if(this.generatedExportData.length === 0)
		            			alert('No orders found to export.')
		            		else {
		                    	this.excelService.exportAsExcelFile(this.generatedExportData, 'at_lazada_orders_report');
		                    	// this.reportForm.controls['type'].setValue('');
		                    	// this.reportForm.controls['process'].setValue('all');
		                    	// this.reportForm.controls['billing'].setValue('unbilled');
		                    	this.generatedExportData = [];
		            		}
                			

			        // },5000);
		                    	
        		}
                
            });

            // this.submitted = false;
		// }
	}

    setTodayDatePicker(): void {

        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        this.reportForm.controls['received_until'].setValue(this.datepipe.transform(new Date(), 'yyyy-MM-dd'));
    }

    generateByBulk() {

    	this.total = 0;
    	this.retrieved = 0;

    	let values =  (<HTMLInputElement>document.getElementById("tnModel")).value.split("\n");
    	this.total = values.length;

      	if(values.length > 0) {

      		let array = []
      		
      		while(values.length) {

      			array.push(values.splice(0,50));

      		}

	        let orders: Array<String> = [];
	        let end = false;

	        this.generatedExportData = [];
	        
	        for (var i = array.length - 1; i >= 0; i--) {

	        	orders = [];
	        	
	        	for (var j = array[i].length - 1; j >= 0; j--) {
	        		
	        		if(array[i][j] !== '')
	            		orders.push(array[i][j]);
	        	}
      			
      			if(i === 0)
      				end = true;

      			this.lazadaOrdersReportBulk(orders, end);
	        }


	    }
    }

    async createBulkFile() {

    	if(this.generatedExportData.length == 0)
    		alert('No orders found to export');
    	else {
    		this.total = 0;
    		this.retrieved = 0;
    		this.excelService.exportAsExcelFile(this.generatedExportData, 'at_lazada_orders_report');
        	(<HTMLInputElement>document.getElementById("tnModel")).value = '';
        	this.generatedExportData = [];
        }
    }

    async lazadaOrdersReportBulk(array: Array<String>, end) {


    	this.bulksubmitted = true;

    	if(this.reportForm.controls['type'].value) {
    		
    		let param = {
    			'type': this.reportForm.controls['type'].value,
    			'tns': array
    		}

			this.apiService.lazadaOrdersReportBulk(param).subscribe(data => {

	            if (data.status) {
	                if(data.data.length !== 0) {

	                	for (var i = data.data.length - 1; i >= 0; i--) {
                    		this.generatedExportData.push(data.data[i]);
                    	}

                    	this.retrieved += data.data.length;

                    	// if(this.total == this.retrieved)
                    		// this.createBulkFile();
	                }
	                else
	                    alert('No orders found to export.');
	            } else {
	                alert(data.message);
	            }

	            
	        });
		}
	}
}
