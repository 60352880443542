import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } 					from  '../api.service';
import { Router} 						from '@angular/router';
import { ExcelService }                       from '../excel.service';
import {AccordionModule} from "ngx-accordion";
import { ngCopy } from 'angular-6-clipboard';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { NgxBarcodeModule } from 'ngx-barcode';

declare function openModal(): any;
declare function closeModal(): any;

@Component({
	selector: 'app-shunt',
	templateUrl: './shunt.component.html',
	styleUrls: ['./shunt.component.css'],
	providers: [ExcelService]
})
export class ShuntComponent implements OnInit {

	submitted = false;

	totalLoaded = 0;

	form: FormGroup;
	deliveries: null;

	showTrackingInput = false;
	copyWithTn = false;
	copyOnlyTn = false;
	hubView = false;

	type = 'assign';
	month = 8;

	options: any;
	shuntOptions: any;
	shipperOptions: any;
	lastRefreshed: any;
	lastUDSCStatsRefreshed: any;
	stats:Object = {};
	uDSCStats:Object = {};
	total:any;
	hubViewId: '';
	selectedShipper: 'all';

	dbUD: Array<Object> = [];
	dbSc: Array<Object> = [];
	failedOrders: Array<String> = [];
	totalBulkTN = 0;
    totalBulkSuc = 0;
    orders: Array<Object> = [];
    generatedExportData: Array<Object> = [];

	role:any;
    audio:any;
    
    keys:any;
    groupByName:any;
    statsLength = 0;
    statsStatus = 'Status';
    statsAging 	= '0';

    retrieved = 0;

    orfCompleted = 0;
  	orfQuality = 22;
  	orfData: any;
  	ordersOrf: Array<Object> = [];
  	contentDataURL: any = []
  	failedOrfOrders: Array<String> = [];
  	element: HTMLElement;
  	pdf: any;
	
	saleData = [
	    { name: "In Hub", value: 105000 },
	    { name: "OFD", value: 55000 },
	    { name: "Delivered", value: 15000 },
	    // { name: "Headset", value: 150000 },
	    // { name: "Fridge", value: 20000 }
	  ];

	  colorScheme = {
    domain: [
      '#f50057', 
      '#ffc400',
      '#00e676', 
      // '#80D8FF', 
      // '#A7FFEB', 
      // '#CCFF90', 
      // '#FFFF8D', 
      // '#FF9E80'
    ]
  };

  elementType = 'img';
  value = '';
  format = 'CODE128';
  lineColor = '#000000';
  width = 1.3;
  height = 54;
  displayValue = true;
  fontOptions = '';
  font = 'calibri';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 14;
  background = '#fff';
  margin = 0;
  marginTop = 0;
  marginBottom = 0;
  marginLeft = 0;
  marginRight = 0;
	
	constructor(
        private apiService: ApiService,
        private router: Router,
        private fb: FormBuilder,
    	private excelService: ExcelService,
    ) {	

	    if (localStorage.getItem('user')) {
			this.role 	= JSON.parse(localStorage.getItem('user')).roles[0].slug;
			if(this.role === 'hubops' || this.role === 'hubmanager' || this.role === 'hubowner') {
				this.hubView = true;
				this.type = 'status';

			}
			
		}

		this.audio = new Audio('assets/files/errorsound.mp3');
        this.audio.load(); 

	}

    get f() { return this.form.controls; }

	ngOnInit() {

		this.form = this.fb.group({
            plate_number: 		['', [Validators.required]],
            origin_id: 			[2, [Validators.required]],
            hub_location_id: 	[1, [Validators.required]],
            // delivery_id: 		['', []],
            tracking_number: 	['', []],
        });

		this.isLoggedIn();
        // setTimeout(()=>{
        //     document.getElementById("plate_number").focus();
        // },0);

        this.shuntIndex();
        this.loadUDSCStats();
	}

	isLoggedIn(): void {

		
	}

	viewStats(array, status, aging) {

		var groupByName = {};

		this.statsLength = array.length;
		this.statsStatus = status;
		this.statsAging = aging;

		if(this.hubView) {

			if(aging === 7)
				this.statsAging = 'Breach';
			else if(aging === 6)
				this.statsAging = 'Pre-Breach';
		}

		array.forEach(function (a) {
		    groupByName [a.order_updates.last_hub_location.name] = groupByName [a.order_updates.last_hub_location.name] || [];
		    groupByName [a.order_updates.last_hub_location.name].push(a);
		});

		var keys = Object.keys(groupByName);
		// 
		// for (var i = keys.length - 1; i >= 0; i--) {
		// 	
		// }

		this.keys = keys;
		this.groupByName = groupByName;

		openModal();
	}

	copyTypeChanged(type) {
		

		if(type === 'withTn') {
			// if(this.copyWithTn)
				this.copyOnlyTn = false

		}
		else if(type === 'onlyTn') {
			// if(this.copyOnlyTn)
				this.copyWithTn = false
		}
	}

	closePopup() {

		closeModal();
	}

	copy(type, object, key) {

		if(type === 'hub') {

			let content = 'URGENT ACTION REQUIRED' + '\n\n' + 
				'Last Status: ' 	+ this.statsStatus + '\n' +
	        	'Aging (Days): ' 	+ this.statsAging + '\n' +  
	        	'Location: ' 		+ key + '\n' + 
	        	'Total Items: ' 	+ object[key].length + '\n' +
	        	'System Guide: ' 	+ 'Perform movement immediately within 3 hours.';

        	if(this.copyWithTn) {
        		content += '\n\n' + 'List of Tracking Numbers:' + '\n'

        		for (var i = 0;  i < object[key].length; i++) {
        			content+= (i+1) + '. ' + object[key][i]['trackingNumber'] + '\n'
        		}
        	}
        	else if(this.copyOnlyTn) {
        		content = ''

        		for (var i = 0;  i < object[key].length; i++) {
        			content += object[key][i]['trackingNumber'] + '\n'
        		}
        	}

        	ngCopy(content);
        	alert('Details copied');
		}
		else if(type === 'hub') {

		}
	}

	copyForShare() {

        
    }

	loadTotalStats() {

		this.apiService.customerForwardTotalStats().subscribe(data => {

	            this.total     = data.data.total;
	        });

	}

	loadUDSCStats() {

		this.apiService.customerForwardTotalUDSCStats().subscribe(data => {

			if(data.status) {
            	this.uDSCStats     = data.data.total;
            	this.lastUDSCStatsRefreshed = new Date();
			}
        });

	}

	shuntIndex() {
		
	    this.apiService.shuntOptions().subscribe(data => {

    		if(data.status) {

            	this.shuntOptions     = data.data;

            	if(this.shuntOptions.destinations.length === 1) {

            		this.hubViewId = this.shuntOptions.destinations[0].id;
    				this.loadIndex(true);
            	}

            	// 
    		}
        });
  	}

  	loadIndex(force:boolean) {

		// if(this.lastRefreshed === null)
	  		if(force || !this.stats) {
				
				this.lastRefreshed = null;
				// this.shipperOptions = null;
				// let hubId = this.form.controls['hub_location_id'].value;
	  			
	  			this.loadTotalStats();
	            		
		    	this.index(3, 'received', 		this.hubViewId);
		    	this.index(10, 'intransit', 	this.hubViewId);
			    this.index(11, 'ib_sc_success', this.hubViewId);
			    this.index(12, 'ob_sc_success', this.hubViewId);
			    this.index(15, 'ib_hb_success', this.hubViewId);
			    this.index(16, 'ob_hb_success', this.hubViewId);
			    this.index(14, 'hb_transfer_sc', this.hubViewId);	
			    this.index(28, 'hb_transfer_hb', this.hubViewId);	
			    this.index(18, 'ofd', 			this.hubViewId);
			    // this.index(19, 'delivered', 	this.hubViewId);
			    this.index(20, 'fd_attempt', 	this.hubViewId);
			    this.index(21, 'ofrd', 			this.hubViewId);
			    this.index(22, 'fd_reattempt', 	this.hubViewId);
			    this.index(23, 'ofrd2', 		this.hubViewId);
			    this.index(25, 'delivery_fd', 	this.hubViewId);
			    this.index(26, 'delivery_fd_int', 	this.hubViewId);
			    this.index(27, 'ib_hb_success_fd', this.hubViewId);	
			    this.index(30, 'delivery_fd_int_sc', this.hubViewId);
			    this.index(31, 'ib_sc_success_fd', this.hubViewId);
			    this.index(32, 'ob_sc_success_fd', this.hubViewId);
			    this.index(34, 'intransit_shipper_fd', this.hubViewId);
			    this.index(35, 'returned_success', this.hubViewId);
			    this.index(37, 'return_attempt_failed', this.hubViewId);
			    // this.index(35, 'return_attempt_failed', this.hubViewId);
			    
			    this.incomingTransit(14, 'incoming', this.hubViewId);	

			    this.stats['delivered'] = null;
	  		}
  		
  	}

  	loadSuccess() {

  		this.index(19, 'delivered', 	this.hubViewId);
  	}

// index(force:boolean) {
	


// 	if(this.lastRefreshed !== null)
// 	if(force || !this.stats) {

// 		this.lastRefreshed = null;

// 	    this.apiService.customerForwardOptions().subscribe(data => {

// 	            this.options     = data.data;
// 	            this.stats       = this.options.stats;

// 	            this.lastRefreshed = new Date();
// 	        });
// 	}
//     // 
//   }

  async index(id = null, key, hubId) {
	
  		this.stats[key] = null;

	    this.apiService.customerForwardOptions(id, hubId, this.selectedShipper).subscribe(data => {
        	this.stats[key]       			= data.data;
        	// this.stats[key]['auto']       	= false;
            this.lastRefreshed = new Date();

            // if(!this.shipperOptions)
            	// this.shipperOptions = data.shippers;


        });
  }

  incomingTransit(id = null, key, hubId) {
	
  		this.stats[key] = null;
        	// this.stats[key]['auto']       	= false;

  		let self = this;

	    this.apiService.customerForwardInTsntOptions(id, hubId, this.selectedShipper).subscribe(data => {
        	this.stats[key]       = data.data;
            this.lastRefreshed = new Date();

    //         while(self.stats[key]['auto']) {
	   //          setTimeout(function(){
				//     self.incomingTransit(14, 'incoming', self.hubViewId);	
				// }, 5000);
    //         }
            // 
        });
  }


	start() {

		this.submitted = true;

		if(this.form.valid) {

			this.showTrackingInput = true;

			// if(this.showTrackingInput)


							// else {
								// this.showTrackingInput = true;
								// this.form.controls['delivery_id'].setValue(data.data.deliveries[0].id);
							// }

				// if(this.showTrackingInput) {
				setTimeout(()=>{
	            	document.getElementById("tracking_number").focus();
	        	},0);
				this.form.controls['plate_number'].disable();
				this.form.controls['origin_id'].disable();
				this.form.controls['hub_location_id'].disable();


				// }
				if(this.form.controls['tracking_number'].value !== '' )
					
			        this.apiService.shunt(this.form.getRawValue()).subscribe(data => {

						if (data.status) {
							if(this.showTrackingInput)
								this.totalLoaded++;
						}	
						else {
							this.audio.play();
							alert(data.message);
							// let audio = new Audio('assets/file/errorsound.mp3');
							// audio.load();
  							// audio.play();
						}
						this.form.controls['tracking_number'].setValue('');
					});
		}
	}

	// onChange(event) {
	// 	this.form.controls['delivery_id'].setValue(event.target.value);

	// 	setTimeout(()=>{
 //            document.getElementById("tracking_number").focus();
 //        },0);

	// 	this.showTrackingInput = true;
	// }

	downloadManifest() {

		let plate = this.form.controls['plate_number'].value;
		let hub = this.form.controls['hub_location_id'].value;
		let origin = this.form.controls['origin_id'].value;

		if(plate !== '' || hub !== '') {

			this.apiService.shuntManifest(this.form.getRawValue()).subscribe(data => {

				if (data.status) {
			        if(data.data.length !== 0)
			        	this.excelService.exportAsExcelFile(data.data, 'shunt_manifest');
			        else
			            alert('No orders found to export.');
			    } else {
			          alert(data.message);
			    }
			});
		}
		else
            alert('Select a delivery first.');
	}

	finish() {
		this.submitted = false;
		this.showTrackingInput = false

		this.totalLoaded = 0;
		this.deliveries = null;

		this.form.controls['plate_number'].enable();
		this.form.controls['plate_number'].setValue('');
		this.form.controls['origin_id'].enable();
		this.form.controls['origin_id'].setValue(5);
		this.form.controls['hub_location_id'].enable();
		this.form.controls['hub_location_id'].setValue('');
		// this.form.controls['delivery_id'].setValue('');
		this.form.controls['tracking_number'].setValue('');

		setTimeout(()=>{
            document.getElementById("plate_number").focus();
        },0);

        
	}

	download(type) {

		// if(this.hubViewId)

			if(type === 'incoming')
				this.apiService.customerInstransitDownload(this.hubViewId).subscribe(data => {

		      if (data.status) {
		          if(data.data.length !== 0)
		              this.excelService.exportAsExcelFile(data.data, 'lazada_orders_'+type);
		          else
		              alert('No orders found to export.');
		      } else {
		          alert(data.message);
		      }

		    });
		    
		    else	
		    this.apiService.customerForwardDownload(type, this.hubViewId).subscribe(data => {

		      if (data.status) {
		          if(data.data.length !== 0)
		              this.excelService.exportAsExcelFile(data.data, 'lazada_orders_'+type);
		          else
		              alert('No orders found to export.');
		      } else {
		          alert(data.message);
		      }

		    });
	  }

	onHubChange(event) {
        this.form.controls['hub_location_id'].setValue(event.target.value);
        // this.loadIndex(true);
    }

    changedHub(event) {

    	this.hubViewId = event.target.value;
    	this.loadIndex(true);
    }

    changedShipper(event) {

    	this.selectedShipper = event.target.value;
    	this.loadIndex(true);
    }

    loadDashboardUD() {

    	// let param = {
    	// 	'month': this.month
    	// };

	    // this.apiService.shuntDbOverall(param).subscribe(data => {
     //    	this.dbUD       = data.data;
     //    });


    }

    clearFailed() {

        this.failedOrders = [];
    }

    countBulkTN() {
      this.totalBulkTN = 0;

     let values =  (<HTMLInputElement>document.getElementById("tnModel")).value.split("\n");

     if(values.length > 0) {
        // let orders: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            this.totalBulkTN++;
        }

      }          
  	}

  	startBulk() {

      this.totalBulkSuc = 0;
      this.totalBulkTN = 0;
        
      this.orders = [];
      
      let values =  (<HTMLInputElement>document.getElementById("tnModel")).value.split("\n");

      if(values.length > 0) {
        let orders: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            orders.push({'trackingNumber': values[i]});
            this.totalBulkTN++;
        }

        this.orders = this.orders.concat(orders);
      }
      	

      for (var i = this.orders.length - 1; i >= 0; i--) {
      		this.bulkInbound(this.orders[i], i); 
      	}	
      // this.bulkInbound(this.orders, 0);
  	}



    async bulkInbound(order, index) {

    	this.form.controls['tracking_number'].setValue(order.trackingNumber);

    	if(this.form.controls['tracking_number'].value !== '' ) {

			        this.apiService.shunt(this.form.getRawValue()).subscribe(data => {

						if (data.status) {
							this.totalBulkSuc++;

							if(this.showTrackingInput)
								this.totalLoaded++;
						}	
						else {
					        this.failedOrders.push(order['trackingNumber']+' | '+data.message);
							// this.totalBulkSuc++;
							// this.audio.play();
							// alert(data.message);
							// let audio = new Audio('assets/file/errorsound.mp3');
							// audio.load();
  							// audio.play();
						}

						if(index === 0)
							this.resetForm();
						// this.form.controls['tracking_number'].setValue('');
					});
			}

	    // this.inboundForm.controls['tracking_number'].setValue(orders[index]['trackingNumber']);

	    //    this.apiService.inbound(this.inboundForm.getRawValue()).subscribe(data => {

	    //         this.totalBulkSuc++;
	    //       if (data.status) {

	    //         if((index+1) < orders.length) {
	    //               this.bulkInbound(orders, index+1);  
	    //         }
	    //         else {
	    //           this.resetForm();
	    //         }
	    //       }  
	    //       else {
	    //         this.failedOrders.push(orders[index]['trackingNumber']+' | '+data.message);

	    //         if((index+1) < orders.length) {
	    //               this.bulkInbound(orders, index+1);  
	    //         }
	    //         else {
	    //           this.resetForm();
	    //         }
	    //       }
	    //     });
	  }

	  resetForm() {
    
    	this.form.controls['tracking_number'].setValue('');
    	(<HTMLInputElement>document.getElementById("tnModel")).value = '';

    	this.orders = [];
    	setTimeout(()=>{
        	document.getElementById("tracking_number").focus();
    	},0);
  		}

  	async createBulkFile() {

    	if(this.generatedExportData.length == 0)
    		alert('No orders found to export');
    	else {
    		// this.total = 0;
    		this.retrieved = 0;
    		this.excelService.exportAsExcelFile(this.generatedExportData, 'at_lazada_mobileunit_report');
        	// (<HTMLInputElement>document.getElementById("tnModel")).value = '';
        	this.generatedExportData = [];
        }
    }

  	downloadMuReport() {

  		// CHECK IF USER IS IN UDSC BEFORE PROCEEDING
  		// GET ALL ORDERS THAT ARE TIED TO MU WITH CUSTOMER AND ADDRESS DETAILS, MU DETAILS, TIMESTAMP. 


  		this.apiService.reportMu().subscribe(data => {

	            if (data.status) {
	                if(data.data.length !== 0) {

	                	for (var i = data.data.length - 1; i >= 0; i--) {
                    		this.generatedExportData.push(data.data[i]);
                    	}

                    	this.retrieved += data.data.length;
                    	this.createBulkFile();

                    	// if(this.total == this.retrieved)
                    		// this.createBulkFile();
	                }
	                else
	                    alert('No orders found to export.');
	            } else {
	                alert(data.message);
	            }

	            
	        });
  	}

  	startBulkOrf() {

      this.ordersOrf = [];
      this.orfCompleted = 0;
      this.failedOrfOrders = [];
      this.contentDataURL = [];

      let values =  (<HTMLInputElement>document.getElementById("tnModelOrf")).value.split("\n");

      if(values.length > 0) {
        let ordersOrf: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            ordersOrf.push({'trackingNumber': values[i]});
        }

          this.ordersOrf = ordersOrf;
          this.bulkOrf(ordersOrf, 0);
      }      
    }

    bulkOrf(orders, index) {

      this.apiService.customerReturnPrintOrf(orders[index]['trackingNumber']).subscribe(data => {
          if (!data.status) {
            this.failedOrfOrders.push(orders[index]['trackingNumber']+' | '+data.message); 

            if((index+1) < orders.length) {
              this.bulkOrf(orders, index+1);  
            }
            else {
              this.ordersOrf = [];
              this.resetOrfForm();
            }
          }
          else  {
            this.orfData = data.data
            this.downloadOrf(data.data, index, orders)
          }

          
      });
    }

    downloadOrf(data, index, orders) {

      let self = this;

      setTimeout(function(){
          let data2 = (<HTMLInputElement>document.getElementById('orf'));  

          html2canvas(data2, {
            scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            scale: self.orfQuality
          }).then(canvas => {
                // closeModal();
                self.contentDataURL.push(canvas) 
                // 
                self.pdf = new jspdf('p', 'cm', 'a6'); // A4 size page of PDF
                
                if(self.contentDataURL.length === 1)
                  self.pdf.addImage(self.contentDataURL[0], 'png', 0, 0, 10.5, 14.8)

                else {
                  self.pdf.addImage(self.contentDataURL[0], 'png', 0, 0, 10.5, 14.8)

                  for (var i = 1; i < self.contentDataURL.length; i++) {
                    self.pdf.addPage('p', 'cm', 'a6');
                    self.pdf.addImage(self.contentDataURL[i], 'png', 0, 0, 10.5, 14.8)
                  }
                }
                
                self.orfCompleted++;

                if((index+1) < orders.length) {
                  self.bulkOrf(orders, index+1);  
                  }
                else {
                  self.pdf.save('multple_tns'+ '.pdf'); // Generated PDF
                  
                  self.contentDataURL = [];
                  self.ordersOrf = [];
                  self.resetOrfForm();
                }
          }); 
      }, 1000);
    }

    resetOrfForm() {
        (<HTMLInputElement>document.getElementById("tnModelOrf")).value = '';
        var msg = '';
        msg =  'Downloaded all AWB requested, please check for errors.';
        // alert(msg);
    }

    clearFailedOrf() {

	    this.failedOrfOrders = [];
	    this.contentDataURL = [];
	  }

}
