import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } 					from  '../../api.service';
import { Router} 						from '@angular/router';
import { ActivatedRoute} 				from '@angular/router';

@Component({
  selector: 'app-lazada-tracking',
  templateUrl: './lazada-tracking.component.html',
  styleUrls: ['./lazada-tracking.component.css']
})
export class LazadaTrackingComponent implements OnInit {

  	data:any;
  	message:any;
	code:any;

    constructor(
    	private route: ActivatedRoute,
  		private apiService: ApiService,
		private router: Router,
	) { }


	ngOnInit() {

		window.scroll(0,0);
		// this.code = this.route.snapshot.paramMap.get('code');
		// // console.log(this.code);
	 //  	this.index();

	  	this.route
			.queryParams
			.subscribe(params => {
				this.code = params['code'] || '';
				this.message = 'Loading...';
				// console.log(this.code);
				// this.location.replaceState('/positions');
				if(this.code !== '')
					this.index();
		});
	}

	index(): void {

		this.apiService.lazadaTrackingDetails(this.code).subscribe(data => {

			this.data 		= data.data;

			if(!data.data)
				window.location.href = 'https://www.starexs.my';
		});
	}

	// hideEmail(email): string {

	// 	const obscureEmail = email => {
	// 	  const [name, domain] = email.split("@");
	// 	  return `${name[0]}${new Array(name.length).join("*")}@${domain}`;
	// 	};

	// 	return obscureEmail("toddmotto@website.com");
	// 	// console.log(email);
	// }

}