import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ExcelService } 	from  '../excel.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';
import { ApiService } 					from  '../api.service';

@Component({
    selector: 'app-import-orders-excel',
    templateUrl: './import-orders-excel.component.html',
    styleUrls: ['./import-orders-excel.component.css'],
    providers: [ExcelService]
})
export class ImportOrdersExcelComponent implements OnInit {

    options:any;
    merchant:any;
    spinnerEnabled = false;
    keys: string[];
    dataSheet = new Subject();
    @ViewChild('inputFile') inputFile: ElementRef;
    isExcelFile: boolean;

    form: FormGroup;

    filename = 'Not selected';

    importOrderCount = 0;
    importedOrderCount = 0;
    failedOrderCount = 1;
    progress = 0;

    importing = false;

    submitted = false;
    fileSelectEnabled = false;

    data: any;

    failedArray: Array<any> = [];

    constructor(
        private excelSrv: ExcelService,
        private apiService: ApiService,
        private fb: FormBuilder
        ) { }

    get f() { return this.form.controls; }

    ngOnInit() {
        this.form = this.fb.group({
            hub_location_id: [2, [Validators.required]],
            profile_id: ['', [Validators.required]],
            type: ['deliver', [Validators.required]],
            status_id: ['', []],
        });

        this.index();
    }

    index(): void {

        this.apiService.importOptions().subscribe(data => {

            this.options         = data.data;
        });
    }

    changedLocation(event) {

        let location =  this.options.locations.find(x => x.id === parseInt(event.target.value));

        if(location && location.is_client){
            // this.form.controls['type'].setValue('deliver');
            // this.form.controls['type'].disable();
        }

        this.form.controls['hub_location_id'].setValue(event.target.value);
    }

    changedMerchant(event) {
       this.merchant = this.options.merchants.find(x => x.id === parseInt(event.target.value));
        // this.form.controls['profile_id'].setValue(event.target.value);
        // console.log(this.form.value)
        // let profile = event.target.value
    }

    onFileChange(evt: any) {

        this.progress = 0;
        this.importedOrderCount = 0;

        let header;
        const target: DataTransfer = <DataTransfer>(evt.target);
        this.isExcelFile = !!target.files[0].name.match(/(.xls|.xlsx|.csv)/);
        this.filename = target.files[0].name;
        if (target.files.length > 1) {
            this.inputFile.nativeElement.value = '';
        }
        if (this.isExcelFile) {
            this.spinnerEnabled = true;
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
                /* read workbook */
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

                /* grab first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                /* save data */
                this.data = XLSX.utils.sheet_to_json(ws);
                this.importOrderCount = this.data.length;
            };

            reader.readAsBinaryString(target.files[0]);

            reader.onloadend = (e) => {
                this.spinnerEnabled = false;
                this.keys = Object.keys(this.data[0]);
                this.dataSheet.next(this.data);
            }
        } else {
            this.inputFile.nativeElement.value = '';
            alert('Only Excel or CSV files are allowed.')
        }

    }

    startImport() {

        this.submitted = true;

        if(this.form.valid) {

            this.importing = true;

            this.form.controls['hub_location_id'].disable();
            this.form.controls['type'].disable();
            this.form.controls['profile_id'].disable();
            this.form.controls['status_id'].disable();

            this.fileSelectEnabled = false;
            this.importedOrderCount = 0;

            // console.log(this.form.value);
            this.saveOrders(this.data, 0);
        }
    }

    saveOrders(data, index: number) {

        let type = this.form.controls['type'].value;

        let param = {
            'hub_location_id':     this.form.controls['hub_location_id'].value,
            'profile_id':         this.form.controls['profile_id'].value,
            'orgName':             'starexs',
            'trackingNumber':      data[index]['Tracking Number'],
            'installation':        ((data[index]['Installation']).toLowerCase() === 'yes' ? 1: 0),
            'platformOrderNumber': data[index]['Order ID'],
            'weight':              data[index]['Total Weight(kg)'],
            'remarks':             ((data[index]['Remark from buyer']) ? data[index]['Remark from buyer']: ''),
            'type':                type,
            'customer': {				
                'name':		       data[index]['Customer Name'],
                'phone':	       data[index]['Mobile Number'],	
                'address': {
                    'zipCode':	   data[index]['Postcode'],
                    'details':	   data[index]['Delivery Address*']
                },
                'geocoding':''
            },
            'items': [
                {
                    'name':        ((data[index]['Product Name']) ? data[index]['Product Name']: 'No name set'),
                    'quantity':    ((data[index]['Quantity']) ? data[index]['Quantity']: '1'),
                    'sku':         ((data[index]['SKU Number']) ? data[index]['SKU Number']: '-')
                }
            ]
        }

        this.apiService.importOrder(param).subscribe(rawdata => {

            if(!rawdata.status) {
                this.failedArray.push({'trackingNumber': param.trackingNumber, 'reason': rawdata.message});
            }

            this.importedOrderCount++;
            this.progress = (this.importedOrderCount)/this.importOrderCount;

            if((index+1) < data.length) {
                this.saveOrders(data, index+1);
            }
            else {
                this.stopImport();
            }
        });
    }

    stopImport() {

        this.importing 			= false;
        this.fileSelectEnabled 	= true;

        this.form.controls['hub_location_id'].enable();
        this.form.controls['profile_id'].enable();
        this.form.controls['type'].enable();
    }

    reset() {
        this.submitted = false;
        this.form.controls['hub_location_id'].enable();
        this.form.controls['type'].enable();
        this.form.controls['profile'].enable();
        this.fileSelectEnabled = true;

        this.filename = 'Not selected';
        this.importOrderCount = 0;
        this.importedOrderCount = 0;

        this.form.controls['hub_location_id'].setValue('');
        this.form.controls['type'].setValue('');
        this.form.controls['profile'].setValue('');

        this.dataSheet = new Subject();

        this.progress = 0;

        this.failedArray = [];

        // CLEAR FAILED ARRAY
    }
}
