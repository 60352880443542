import { Component, OnInit, Input } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

	private agree = false;
	form: FormGroup;
    submitted = false;

    options:any;

    declarativeFormCaptchaValue: any;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder

	) { }

	get f() { return this.form.controls; }

	ngOnInit() {
		 this.form = this.fb.group({
            first_name: ['', [Validators.required]],
            last_name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            hub_location_id: ['', [Validators.required]],
            role: ['', [Validators.required]],
        });

		 this.index();
	}

	index(): void {

		this.apiService.registerOptions().subscribe(data => {

			this.options 		= data.data;
		});
	}

	resolved(captchaResponse: string) {
		// console.log(`Resolved captcha with response ${captchaResponse}:`);
	}

	register(): void {

		this.submitted = true;

		if (this.form.valid) {
			this.apiService.register(this.form.value).subscribe(data => {
				if (data.status)
					this.form.reset();
				
				alert(data.message);
				this.submitted = false;
			});
		}
	}

	changedLocation(event) {

		let location =  this.options.locations.find(x => x.id === parseInt(event.target.value));

		if(location.is_client){
			this.form.controls['role'].setValue('client');
		}
		else if(location.transit_hub){
			this.form.controls['role'].setValue('hubmanager');
		}
		else if(location.code === 'MERHB'){
			this.form.controls['role'].setValue('client');
		}
		else
			this.form.controls['role'].setValue('');

		this.form.controls['hub_location_id'].setValue(event.target.value);
	}


	toggletnc(event) {
	    if ( event.target.checked ) {
	         this.agree = true;
	    } else {
	    	this.agree = false;
					}
	}

	passwordMatch(): boolean {

		if (this.form.controls['password'].value === this.form.controls['cPassword'].value) {
			return true;
		}

		return false;
	}
}
