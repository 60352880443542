import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } 					from  '../api.service';
import { Router} 						from '@angular/router';

@Component({
  selector: 'app-inbound',
  templateUrl: './inbound.component.html',
  styleUrls: ['./inbound.component.css']
})
export class InboundComponent implements OnInit {

  options:any;
    data:any;
    trackingNumber:any = null;
    resultsLength = 1;
    updateWeight = false;

    submitted = false;
    showWeightInput = false;

    inboundForm: FormGroup;

    scannedItems:any = [];

    orders: Array<Object> = [];
    ordersMU: Array<Object> = [];
    element: HTMLElement;
    failedOrders: Array<String> = [];
    failedOrdersMU: Array<String> = [];
    audio:any;
    totalBulkTN = 0;
    totalBulkTNMU = 0;
    totalBulkSuc = 0;
    totalBulkSucMU = 0;
    totalSingleSuc = 0;

    muActionType = '';
    muExist = false;
    muID = '';
    newMuID = '';
    musubmitted = false;
    muData : any;
    muType = "";
    muTn = "";
    muCount = 0;

    constructor(
        private apiService: ApiService,
        private router: Router,
        private fb: FormBuilder
        ) { 
        this.audio = new Audio('assets/files/errorsound.mp3');
        this.audio.load();
    }


    get inf() { return this.inboundForm.controls; }

    ngOnInit() {

        this.inboundForm = this.fb.group({
            tracking_number: ['', [Validators.required]],
            package_weight: ['', [Validators.pattern(/^[.\d]+$/)]],
            hub_location_id: ['', [Validators.required]],
            // package_weight: ['', [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
            // status_id: ['', []],
            // hub_location_id: ['', []],
            // start_date: ['', []],
            // end_date: ['', []],
        });

        setTimeout(()=>{
            document.getElementById("tracking_number").focus();
        },0);

        

        this.inboundOptions();
    }

    inboundOptions(): void {

        this.apiService.inboundOptions().subscribe(data => {

            // console.log(data);
            this.options 		= data.data;

            if (localStorage.getItem('user')) {
                // console.log(JSON.parse(localStorage.getItem('user')));
                // let user = JSON.parse(localStorage.getItem('user'));

                this.inboundForm.controls['hub_location_id'].setValue(JSON.parse(localStorage.getItem('user'))['hub']);
            }
        });
    }

    toggleUpdateWeight(): void {
        this.updateWeight = !this.updateWeight;
        
        if(!this.updateWeight) {
            this.showWeightInput = false;
            this.inboundForm.controls['package_weight'].setValue('');
            
            setTimeout(()=>{
                document.getElementById("tracking_number").focus();
            },0);   
        }
        else {
            if(this.inboundForm.controls['tracking_number'].value !== '') {
                this.inboundForm.controls['package_weight'].setValue('');
                this.showWeightInput = true;
                setTimeout(()=>{
                    document.getElementById("package_weight").focus();
                },0);   
            }
        }
    }

    inbound() {

        this.submitted = true;

        if(this.inboundForm.controls['tracking_number'].value !== '' && this.updateWeight && !this.showWeightInput) {
            this.showWeightInput = true;
            setTimeout(()=>{
                document.getElementById("package_weight").focus();
            },0);
        }
        else if(this.inboundForm.valid) {

            this.apiService.inbound(this.inboundForm.value).subscribe(data => {
                
                this.muTn = this.inboundForm.controls['tracking_number'].value;

                this.inboundForm.controls['tracking_number'].setValue('');
                this.inboundForm.controls['package_weight'].setValue('');
                this.showWeightInput = false;

                setTimeout(()=>{
                    document.getElementById("tracking_number").focus();
                },0);

                if (!data.status){
                    this.data = null;
                    
                      this.audio.play();
                    alert(data.message);
                }
                else {
                    this.totalSingleSuc++;
                    this.data = data.data;

                    if(this.muID !== '')
                        this.addIntoMu();
                }
                this.submitted = false;
            });
        }
    }


    updateOrderWeight() {

        this.submitted = true

    	if(this.inboundForm.valid) {
	        this.apiService.updateOrderWeight(this.inboundForm.value).subscribe(data => {
	        
	            if (!data.status){
	                alert(data.message);
	                
	                setTimeout(()=>{
	                    document.getElementById("package_weight").focus();
	                },0);
	            }
	            else {
	                this.data = null;
                    this.trackingNumber = null;
	                this.inboundForm.reset();
	                
	                setTimeout(()=>{
	                    this.inboundForm.controls['package_weight'].setValue('');
	                    document.getElementById("tracking_number").focus();
	                },0);
	            }
	            this.submitted = false;
	        });
	    }
    }

    startBulk() {

      this.totalBulkSuc = 0;
      this.totalBulkTN = 0;
        
      this.orders = [];
      
      let values =  (<HTMLInputElement>document.getElementById("tnModel")).value.split("\n");

      if(values.length > 0) {
        let orders: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            orders.push({'trackingNumber': values[i]});
            this.totalBulkTN++;
        }

        this.orders = this.orders.concat(orders);
      }
      
      this.bulkInbound(this.orders, 0);
  }

  startBulkMU() {

      this.totalBulkSucMU = 0;
      this.totalBulkTNMU = 0;
        
      this.ordersMU = [];
      
      let values =  (<HTMLInputElement>document.getElementById("tnModelMU")).value.split("\n");

      if(values.length > 0) {
        let orders: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            orders.push({'trackingNumber': values[i]});
            this.totalBulkTNMU++;
        }

        this.ordersMU = this.ordersMU.concat(orders);
      }
      
      // this.bulkInbound(this.orders, 0);

      for (var i = this.ordersMU.length - 1; i >= 0; i--) {
        
        if(this.muID !== '' && this.muActionType !== '') {
          this.muTn = this.ordersMU[i]['trackingNumber'];
          this.scanMuTn();
        }
        else {
          alert('Fill up MU required details first.')
          break;
        }
        // if(i === 0)
          
      }
  }

  countBulkTN() {
      this.totalBulkTN = 0;

     let values =  (<HTMLInputElement>document.getElementById("tnModel")).value.split("\n");

     if(values.length > 0) {
        // let orders: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            this.totalBulkTN++;
        }

      }          
  }

  countBulkTNMU() {
      this.totalBulkTNMU = 0;

     let values =  (<HTMLInputElement>document.getElementById("tnModelMU")).value.split("\n");

     if(values.length > 0) {
        // let orders: Array<Object> = [];
        for (var i = values.length - 1; i >= 0; i--) {
          if(values[i] !== '')
            this.totalBulkTNMU++;
        }

      }          
  }

  getMu() {
      // this.musubmitted = true;
      if(this.muID === '')
          alert('Mobile unit ID cannot be empty.')
      else
          this.getMuDetails(this.muID);
  }

  saveMu() {

      if(this.muID === '')
          alert('Mobile unit ID cannot be empty.')
      else if(this.muType === '')
          alert('Mobile unit type cannot be empty.')
      
      else {
          let param = {

              'code': this.muID,
              'type': this.muType
          }

          this.apiService.saveMuDetails(param).subscribe(data => {

              this.musubmitted = false;

              if (data.status) {
                  this.getMu();
                    // this.muExist = true;
                    // this.muData = data.data;
              }  
              else {
                // this.muExist = false;
                alert(data.message);
              }
            });

          
      }
  }

  addIntoMu() {

      
      let param = {

          'code':             this.muID,
          'trackingNumber':   this.muTn
      }

      this.apiService.addIntoMu(param).subscribe(data => {

          // this.musubmitted = false;

          if (data.status) {
              // this.getMu();
              this.muCount++;
              this.muTn = '';
              this.totalBulkSucMU++;
                // this.muExist = true;
                // this.muData = data.data;
          }  
          else {
            // this.muExist = false;
            if(this.ordersMU.length = 0)
              alert(data.message);
            else {
              this.muTn = '';
              this.failedOrdersMU.push(param['trackingNumber'] +' | '+data.message);
            }
          }
        });

          
      
  }

  removeFromMu() {

      
      let param = {

          'code':             this.muID,
          'trackingNumber':   this.muTn
      }

      this.apiService.removeFromMu(param).subscribe(data => {

          // this.musubmitted = false;

          if (data.status) {
              // this.getMu();
              this.muCount--;
              this.muTn = '';
              this.totalBulkSucMU++;
                // this.muExist = true;
                // this.muData = data.data;
          }  
          else {
            
            // this.muExist = false;
            if(this.ordersMU.length = 0)
              alert(data.message);
            else
              this.failedOrdersMU.push(param['trackingNumber'] +' | '+data.message);
          }
        });

          
      
  }

  async scanMuTn() {

      if(this.muID === '')
          alert('Mobile unit ID cannot be empty.')
      else if(this.muTn === '')
          alert('Tracking number cannot be empty.')
      else if(this.muActionType === '')
          alert('Select type of action first.')
      
      else {
          if(this.muActionType === 'add')
              this.addIntoMu();
          else if(this.muActionType === 'remove')
              this.removeFromMu();

      }
  }


getMuDetails(muID) {

    // this.inboundForm.controls['tracking_number'].setValue(orders[index]['trackingNumber']);

       this.apiService.getMuDetails(muID).subscribe(data => {

           this.musubmitted = true;
          
          if (data.status) {
                this.muExist = true;
                this.muData = data.data;
                this.muCount = data.data.muCount;
          }  
          else {
            this.muExist = false;
            alert(data.message);
          }
        });
  }

  clearMu() {

      this.musubmitted = false;
      this.muExist = false;
      this.muData = null;
      this.muID = '';
      this.muCount = 0;
      this.muTn = '';
  }

  clearMuTn() {

      this.muTn = '';
  }

  clearNewMu() {

      this.newMuID = '';
  }

  transferMU() {

    let param = {

          'fromMu':             this.muID,
          'toMu':               this.newMuID
      }

      this.apiService.transferMu(param).subscribe(data => {

          // this.musubmitted = false;

          if (data.status) {
                this.clearMu();
                this.clearNewMu();
          }  
          // else {
            // this.muExist = false;
            alert(data.message);
          // }
        });      
  }



    async bulkInbound(orders, index) {

    this.inboundForm.controls['tracking_number'].setValue(orders[index]['trackingNumber']);

       this.apiService.inbound(this.inboundForm.getRawValue()).subscribe(data => {

            this.totalBulkSuc++;
          if (data.status) {

            if((index+1) < orders.length) {
                  this.bulkInbound(orders, index+1);  
            }
            else {
              this.resetForm();
            }
          }  
          else {
            this.failedOrders.push(orders[index]['trackingNumber']+' | '+data.message);

            if((index+1) < orders.length) {
                  this.bulkInbound(orders, index+1);  
            }
            else {
              this.resetForm();
            }
          }
        });
  }

  resetForm() {
    
    this.inboundForm.controls['tracking_number'].setValue('');
    (<HTMLInputElement>document.getElementById("tnModel")).value = '';

    this.orders = [];
  }

  resetFormMU() {
    
    // this.inboundForm.controls['tracking_number'].setValue('');
    (<HTMLInputElement>document.getElementById("tnModelMU")).value = '';
    this.totalBulkSucMU = 0
    this.totalBulkTNMU = 0
    this.ordersMU = [];
    this.failedOrdersMU = [];
  }

    clearFailed() {

        this.failedOrders = [];
    }

    clearFailedMU() {

        this.resetFormMU();
    }

    clearSingleSuc() {
        this.totalSingleSuc = 0;
    }

    onHubChange(event) {
        this.inboundForm.controls['hub_location_id'].setValue(event.target.value);
    }
}
