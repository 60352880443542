import { Component, OnInit, Input } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {

	form: FormGroup;
    submitted = false;


  constructor(
  	private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder
	) { }

  ngOnInit() {
  	this.form = this.fb.group({
        password: ['', 	[Validators.required, Validators.minLength(6)]],
        newPassword: ['', 	[Validators.required, Validators.minLength(6)]],
        cNewPassword: ['', 	[Validators.required, Validators.minLength(6)]],
    });
  }

  get f() { return this.form.controls; }

  update(): void {
		this.submitted = true;

		if (this.form.valid) {
			this.apiService.userChangePassword(this.form.value).subscribe(data => {

				if (data.status == true) {
					this.form.reset();
				}

				this.submitted = false;
				alert(data.message);
			});
		}
	}

}
