import { Component, OnInit, Input } 	from '@angular/core';
import { Router} 						from '@angular/router';
import { ActivatedRoute } 				from '@angular/router';
import { Location } 					from '@angular/common';
import { NgForm } 						from '@angular/forms';
import { ApiService } 					from  '../api.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-signin',
	templateUrl: './signin.component.html',
	styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

	form: FormGroup;
    submitted = false;

    // declarativeFormCaptchaValue: any;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		private router: Router,
		private location: Location,
		private fb: FormBuilder
	) { }

	ngOnInit() {
		if(JSON.parse(localStorage.getItem('user')))
            this.router.navigate(['/orders']);
            
		this.form = this.fb.group({
            email: ['', 	[Validators.required, Validators.email]],
            password: ['', 	[Validators.required, Validators.minLength(6)]],
        });
	}

	get f() { return this.form.controls; }

	// resolved(captchaResponse: string) {
	// 	// console.log(`Resolved captcha with response ${captchaResponse}:`);
	// }

	login(): void {
		// if (!form.valid) {
		// 	alert('Please make sure you are not a robot.');
		// } 
		// else {
			this.submitted = true;

			if (this.form.valid) {
				this.apiService.login(this.form.value).subscribe(data => {
					if (data.status == true) {
						localStorage.setItem('user', JSON.stringify(data.data));
						this.router.navigate(['/orders']);
					} else {
						alert(data.message);
					}
				});
			}
		// }

	}
}
